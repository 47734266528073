




import { Component } from "vue-property-decorator";
import StudentForm from "./StudentForm.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import StudentMixin from "@/mixins/StudentMixin";
import StudentStatusMixin from "@/mixins/StudentStatusMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { formatStudentName } from "@/utils/NameUtil";
import TabMixin from "@/mixins/TabMixin";

const StudentModule = namespace("student");

@Component({
  components: { StudentForm },
})
export default class StudentCreate extends mixins(StudentStatusMixin, StudentMixin, TabMixin) {
  public name = "StudentCreate";

  @StudentModule.Action("create")
  public create!: (options: ICrudOptions) => Promise<void>;

  @StudentModule.Getter("getIsLoading")
  public getIsLoading!: boolean;

  public internalLoading = false;

  private fullPath: string | undefined = undefined;

  public mounted(): void {
    this.fullPath = String(this.$route.fullPath);
  }

  public async onSave(data: { newChecklistTemplateId: any; student: Record<any, any>; educations: Array<Record<any, any>>; file: File | null }): Promise<void> {
    this.internalLoading = true;
    try {
      const obj: {
        student: any;
        educations?: any;
        newChecklistTemplateId?: any;
      } = {
        student: this.studentObject({
          ...data.student,
        }),
      };
      const educations = [];
      for (const education of data.educations) {
        const educationObject = this.educationObject(education);

        if (this.isCostBearerIdBillingAddress(education.costBearerTypeId)) {
          educationObject.billingAddress = this.billingAddressObject(education);
        }
        educationObject.automaticPaymentEnabled = education.automaticPaymentEnabled;
        educations.push(educationObject);
      }

      obj.educations = educations;
      obj.newChecklistTemplateId = data.newChecklistTemplateId?.id;
      obj.student.mergeDataToExisting = true;

      await this.create({
        data: obj,
        resource: "students/create-with-educations",
      }).then(() => {
        const message = "messages.message_merge_demo_account";
        this.$store.dispatch(
          "snackbar/setMessage",
          {
            text: this.$t(message),
            type: "success",
          },
          { root: true }
        );
      });

      if (data.file) {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("studentId", this.studentId);
        await this.uploadAvatar(formData);
      }

      if (this.success && this.studentId) {
        this.removeTabByIdAction(this.$route.fullPath);
        this.setCustomLabelAction(formatStudentName(obj.student.firstName, obj.student.lastName));
        // await this.$router.push({
        //   name: "StudentEdit",
        //   params: { id: this.studentId },
        // });
        this.closeTab(this.fullPath ?? "", this.name);
      }
      this.$root.$emit("refresh-students-table");
      this.internalLoading = false;
    } catch (exception) {
      console.error(exception);
      this.internalLoading = false;
    }
  }

  public get loading() {
    return this.avatarIsLoading || this.getIsLoading || this.internalLoading;
  }
}
